/* CSS HSL 
--magnolia: hsla(251, 48%, 95%, 1);
--blue-gray: hsla(210, 62%, 60%, 1);
--antique-fuchsia: hsla(319, 29%, 48%, 1);
--midnight-green-eagle-green: hsla(195, 53%, 24%, 1);
--smoky-black: hsla(14, 36%, 9%, 1);

Light Blue: #2AACE2
Medium Blue: #0184A3
Grey: #606161
Dark Grey: #3D3D3D
*/

html, body, #root{
  width: 100%;
  height: 100%;

  background: #3D3D3D;
  color: #FFF;
}

button{
  border-width: 1px;
  cursor: pointer;
  font-family: Montserrat, sans-serif;
  font-weight: 700;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: .15em;
}

.App {
  text-align: center;
  font-family: Montserrat, sans-serif;

  height: 100%;
  display: flex;
  flex-direction: column;
}
.App h1{
  flex-grow: 0;
}
.App .view{
  flex-grow: 1;
}

footer{
  background: #3D3D3D;
  color: #FFF;
  height: 54px;
  padding: 10px;
}
footer a{
  height: 100%;
  display: block;
}

.question-parent{
  padding: 20px 4px;
  background: white;
  color: #000;
  margin: auto;
  margin-bottom: 8px;
  font-size: 18px;
  max-width: 978px;
  font-family: "Helvetica Neue",Arial,sans-serif;
}

button{
  padding: 13px 26px;
  border-radius: 3px;
  font-weight: bold;
}
.answer-button{
  display: block;
  padding: 4px;
  width: 100%;
  margin-bottom: 4px;
}
.selected{
  background: green;
  color: white;
}

.answer-parent{
  max-width: 400px;
  margin: auto;
  margin-bottom: 8px;
}

.button-parent{
  margin-top: 16px;
}
.button-parent button{
  width: 100px;
  text-align: center;
}
.button-parent button:first-child{
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.button-parent button:last-child{
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.countdown{
  position: relative;
  max-width: 200px;
  margin: auto;
}
@keyframes fillWidth{
  0% {width: 0}
  95% {width: 100%}
  100% {width: 100%}
}
.countdown-fill{
  position: absolute;
  left: 0;
  top: 100%;
  height: 2px;
  background:#0184A3;
}
.countdown-parent{
  margin-top: 20px;
  margin-bottom: 20px;
}
.radio-parent{
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: #000;
}
.radio-label{
  display: flex;
  flex-direction: column;
  padding: 15px 5px;
  border: 1px solid rgba(0, 0, 0, .1);
  position: relative;
  font-weight: normal;
  background: white;
  min-width: 60px;
  transition: all .1s linear;
  user-select: none;
}
.radio-label:hover{
  text-shadow: 0 0 10px #777;
}
.radio-label:active{
  background: #ddd;
}
.radio-label:first-child{
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.radio-label:last-child{
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.radio-label:hover{
  border: 1px solid rgba(0, 0, 0, .2);
  
}
.radio-label input{
  margin: 0;
  display: block;
  height: 0;
}
.radio-label .confirm{
  background: transparent;
  color: transparent;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.radio-label .confirm.active{
  background: #2AACE2;
  color: #fff;
}
.mrg-lr-auto{
  margin-left: auto;
  margin-right: auto;
}
.mrg-tb-15{
  margin-top: 15px;
  margin-bottom: 15px;
}
.pad-lr-8{
  padding-left: 8px;
  padding-right: 8px;
}
.flex-h{
  display: flex;
}
.flex-justify-between{
  justify-content: space-between;
}
label{
  font-weight: bold;
}
.name-form{
  
  height: 100%;
  width: 410px;
  margin: auto;
}
.name-form input{
  border-color: white;
  padding-left: 10px;
  width: 180px;
  border: 0;
}
.name-form input, .name-form button{
  line-height: 3em;
  padding: 0px 10px 0 10px;
}
.name-form button, .name-form .spacer{
  width: 200px;
}
.name-form input, .name-form .spacer{
  margin-bottom: 2px;
}
.name-form .invalid{
  color: #ff3939;
}

button.primary{
  outline: 0;
  background: transparent;
  border: 2px solid white;
  color: white;
  transition: 0.1s background-color linear, 0.1s color linear;
}
button.primary:hover{
  background: hsl(198, 76%, 53%);
  border-color: hsl(198, 76%, 53%);
  color: white;
}
button.primary:active, button.primary:selection{
  background: hsl(198, 76%, 53%);
  border-color: hsl(198, 76%, 53%);
  color: white;
}
button.primary:disabled, button.primary:disabled:hover{
  border-color: #888;
  color: #888;
  background: transparent;
}

button.small{
  outline: 0;
  background: white;
  border-width: 2px solid #000;
  transition: 0.1s background-color linear, 0.1s color linear;
  margin: 2px;
  padding: 6px 13px;
  font-size: 11px;
}
button.small:hover{
  background: #242424;
  color: white;
}
button.small:active, button.small::selection{
  background: #242424;
  color: white;
}
button.small:disabled, button.small:disabled:hover{
  background: #999;
  color: #888;
}

.logo-parent{
  display: block;
  background: white;
  padding: 10px;
  margin-bottom: 10px;
  max-height: 10vh;
}
.logo-parent .logo{
  height: 100%;
}
label.name{
  display: block;
  font-weight: normal;
  width: 100%;
  padding: 10px;
  background: white;
  color: #000;
}
.thank-you{
  width: 30vh;
  height: 30vh;
  background: white;
  padding: 30px;
}
.circle-img{
  display: block;
  margin: auto;
  border-radius: 60vh;

  background-size: cover;
  background-position-x: center;
}

.App.archive{
  background: white;
}
.archive .logo-parent{
  display: none;
}
.archive-view{
  width: 5in;
  margin: auto;
  color: black;
  border: 1px solid black;
}
.archive-preamble-table{
  margin: auto;
  margin-bottom: 30px;
}
.archive-logo{
  width: 2in;
  margin: auto;
}
.archive .preamble{
  font-size: 18px;
}
.archive .name{
  font-size: 24px;
  display: block;
}
.archive-answer-table{
  width: 100%;
  border-collapse: collapse;
}
.archive-answer-table th{
  text-align: center;
}
.archive-answer-table tr:nth-child(odd){
  background: #eee;
}

@media print{
  .archive-view{
    width: 3in;
  }
  .archive-logo{
    width: 1in;
  }
  .archive .preamble{
    font-size: 14px;
  }
  .archive .name{
    font-size: 20px;
  }
  .archive-answer-table{
    margin-bottom: 200px;
  }
}

@media screen and (min-width: 550px){
  /* tablet+desktop styles */
  .name-form button{
    margin-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .tablet-mrg-t-10{
    margin-top: 10px;
  }
  .tablet-w-100, .name-form .tablet-w-100{
    width: 100%;
  }
}
@media screen and (max-width: 550px){
  /* mobile styles */
  .name-form{
    width: 201px;
  }
  .flex-h.mobile-v{
    flex-direction: column;
  }
  .mobile-flex-v{
    display: flex;
    flex-direction: column;
  }
  .mobile-flex-g-1{ flex-grow: 1; }
  .mobile-mrg-t-10{
    margin-top: 10px;
  }
  .circle-img{    
    width: 60vw;
    height: 60vw;
  }
  .embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }
}